<template>
  <div>
    <div class="mb-4">
      <div class="version">
        {{ $t("app.versions") }} {{ training?.version }}
      </div>

      <div v-if="trimHTML(getText(training?.texts, 'details'))">
        {{ trimHTML(getText(training?.texts, "details")) }}
      </div>
    </div>

    <div class="grid grid-cols-2 content-start gap-4">
      <VLine :label="$t('app.instructors', 2)" />

      <div class="flex flex-wrap">
        <div v-for="(instructor, index) in instructors" :key="instructor.id">
          <VImage
            class="w-10 h-10 zoom-in image-fit"
            :class="{ '-ml-5': index !== 0 }"
            :src="getAvatarURL(instructor?.avatar?.view_path)"
            :content="`${instructor.firstname} ${instructor.lastname}`"
          />
        </div>
      </div>

      <VLine :label="$t('app.competencies', 2)" />

      <div class="flex flex-wrap">
        <div
          v-for="(competency, index) in competencies"
          :key="competency.id"
          :class="index !== competencies.length - 1 ? 'comma' : ''"
        >
          {{ getText(competency?.texts, "name") }}
        </div>
      </div>

      <VLine :label="$t('app.tags', 2)" />

      <div class="flex flex-wrap">
        <div
          v-for="(tag, index) in tags"
          :key="tag.id"
          class="cursor-pointer"
          :class="index !== tags.length - 1 ? 'comma' : ''"
          @click="onClickRedirectToTag(tag)"
        >
          {{ tag.name }}
        </div>
      </div>

      <slot name="more-content" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VLine from "@/components/VLine";
import VImage from "@/components/VImage";

export default {
  components: {
    VImage,
    VLine
  },
  props: {
    training: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();

    // Computed
    const instructors = computed(() => props.training?.instructors ?? []);
    const competencies = computed(() => props.training?.competencies ?? []);
    const tags = computed(() => props.training?.tags ?? []);

    // Composables
    const { getText } = useTexts();
    const { getAvatarURL, trimHTML } = useDisplay();

    // Methods
    const onClickRedirectToTag = tag => {
      router.push({
        name: "tag",
        params: { id: tag?.id }
      });
    };

    return {
      instructors,
      competencies,
      tags,
      onClickRedirectToTag,
      // useTexts
      getText,
      // useDisplay
      trimHTML,
      getAvatarURL
    };
  }
};
</script>
