<template>
  <VCard
    :title-class="isView || hideActions ? '' : 'cursor-pointer'"
    actions-class="bg-theme-5"
    @click:title="onClickRedirectToLesson"
  >
    <template #title>
      <div class="flex items-center">
        <VImage :color="color" :name="name" class="mr-2" />

        <div>
          <div class="font-medium">
            {{ name }}
          </div>

          <div class="version">
            {{ $t("app.versions") }} {{ resource?.lesson?.version }}
          </div>
        </div>
      </div>
    </template>

    <template #title-actions>
      <VChip
        v-if="resource?.status"
        :text="$t(`app.${resource?.status}`)"
        :class="getStatusColor(resource?.status)"
      />
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-2">
        <div v-if="details" class="col-span-2">
          {{ details }}
        </div>

        <VLine
          v-if="trainingName"
          class="col-span-2"
          :label="$t('app.trainings')"
          :value="trainingName"
          :class="{ 'grid col-span-2 grid-cols-2': isView }"
        />

        <VLine
          :label="$t('app.type')"
          :class="{ 'grid col-span-2 grid-cols-2': isView }"
        >
          <template #value>
            <div class="flex items-center">
              <div class="lesson-type">
                {{ type }}
              </div>
            </div>
          </template>
        </VLine>

        <VLine
          :label="$t('app.duration')"
          :class="{ 'grid col-span-2 grid-cols-2': isView }"
        >
          <template #value>
            <div class="lowercase">
              {{ duration }}
            </div>
          </template>
        </VLine>

        <VLine
          :label="$t('app.started_on')"
          :class="{ 'grid col-span-2 grid-cols-2': isView }"
        >
          <template #value>
            <div>
              <VDate :date="resource?.started_on ?? ''" />
            </div>
          </template>
        </VLine>

        <VLine
          :label="$t('app.completed_on')"
          :class="{ 'grid col-span-2 grid-cols-2': isView }"
        >
          <template #value>
            <div>
              <VDate :date="resource?.completed_on ?? ''" />
            </div>
          </template>
        </VLine>
      </div>
    </template>

    <template v-if="!isView && !hideActions" #actions>
      <div class="flex justify-end">
        <VButton
          class="btn-primary"
          :label="$t('app.view_lesson')"
          @click="onClickRedirectToLesson"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VChip from "@/components/VChip";
import VLine from "@/components/VLine";
import VButton from "@/components/VButton";
import VImage from "@/components/VImage";
import VDate from "@/components/VDate";

export default {
  components: {
    VDate,
    VCard,
    VChip,
    VButton,
    VLine,
    VImage
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    isView: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getStatusColor } = useColor();
    const { getText } = useTexts();

    // Computed
    const registration = computed(() => props?.resource?.registration);
    const training = computed(() => registration.value?.training);
    const trainingName = computed(() => getText(training.value?.texts, "name"));
    const lesson = computed(() => props?.resource?.lesson);
    const name = computed(() => getText(lesson.value?.texts, "name"));
    const details = computed(() => getText(lesson.value?.texts, "details"));
    const color = computed(() => lesson.value?.colour);
    const durationType = computed(() => lesson.value?.duration_type);
    const durationTime = computed(() => lesson.value?.duration);
    const type = computed(() => lesson.value?.type);
    // eslint-disable-next-line
    const duration = computed(() => durationTime.value && durationType.value ? `${durationTime.value} ${t(`app.${durationType.value}`)}` : "");

    // Methods
    const onClickRedirectToLesson = () => {
      const id = props.resource?.id;

      if (!id) {
        return;
      }

      context.emit("click", id);
    };

    return {
      name,
      color,
      details,
      duration,
      type,
      trainingName,
      onClickRedirectToLesson,
      // useColor
      getStatusColor
    };
  }
};
</script>
