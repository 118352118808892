<template>
  <div class="grid grid-cols-1 gap-8">
    <LessonCard
      v-for="item in lessons"
      :key="item?.id"
      :resource="item"
      @click="onClickRedirectToLesson"
    />

    <AttestationCard
      v-for="item in attestations"
      :key="item?.id"
      :resource="item"
      @click="onClickRedirectToAttestation"
    />

    <EvaluationCard
      v-for="item in evaluations"
      :key="item?.id"
      :evaluation-type="getEvaluationType(item)"
      :resource="item"
      @click="onClickRedirectToEvaluation"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
// Composables
import useOptions from "@/composables/useOptions";
// Components
import AttestationCard from "@/views/learning/attestations/AttestationCard";
import LessonCard from "@/views/learning/lessons/LessonCard";
import EvaluationCard from "@/views/learning/evaluations/EvaluationCard";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    AttestationCard,
    LessonCard,
    EvaluationCard
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();

    // Composables
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const lessons = computed(() => props.resource?.lessons);
    const attestations = computed(() => props.resource?.attestations);
    const evaluations = computed(() => props.resource?.evaluations);

    // Methods
    const getEvaluationType = registrationEvaluation => {
      const types = {
        [ALL_OPTIONS.QUIZ.value]: evaluationTypes.QUIZ,
        [ALL_OPTIONS.OBSERVATION.value]: evaluationTypes.OBSERVATION
      };

      return types[registrationEvaluation?.evaluation?.type] ?? "";
    };

    const onClickRedirectToLesson = lessonId => {
      router.push({
        name: "learning-registrations-trainings-lessons-details",
        params: {
          id: props.resource?.id,
          lessonId
        }
      });
    };

    const onClickRedirectToAttestation = attestationId => {
      router.push({
        name: "learning-registrations-trainings-attestations-details",
        params: {
          id: props.resource?.id,
          attestationId
        }
      });
    };

    const onClickRedirectToEvaluation = (evaluationId, evaluationType) => {
      const types = {
        [evaluationTypes.QUIZ]: "quizzes",
        [evaluationTypes.OBSERVATION]: "obsevations"
      };
      const type = types[evaluationType];

      router.push({
        name: `learning-registrations-trainings-evaluations-${type}-details`,
        params: {
          id: props.resource?.id,
          evaluationId
        }
      });
    };

    return {
      getEvaluationType,
      lessons,
      attestations,
      evaluations,
      onClickRedirectToLesson,
      onClickRedirectToAttestation,
      onClickRedirectToEvaluation
    };
  }
};
</script>
