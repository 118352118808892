<template>
  <div>
    <VTitle class="flex justify-baseline items-center route-title">
      {{ $t("app.registrations", 2) }}

      <VHint class="ml-4 text-base">
        {{ $t("hints.learning_registrations_trainings") }}
      </VHint>
    </VTitle>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div class="col-span-3 lg:col-span-1">
        <TrainingCard
          v-if="data?.training"
          :training="data?.training"
          is-view
        />

        <Versions
          v-if="isInstructor"
          class="mt-8"
          :route="route"
          :versions="data?.training?.versions"
        />
      </div>

      <div class="col-span-3 lg:col-span-2">
        <div class="mb-8">
          <VCard>
            <template #content>
              <RegistrationDates :resource="data" />

              <RegistrationProgress
                class="mt-2"
                :summary="data?.summary ?? {}"
              />
            </template>
          </VCard>
        </div>

        <Outline :resource="data" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useTraining from "@/composables/useTraining";
import useTexts from "@/composables/useTexts";
// Components
import VTitle from "@/components/VTitle";
import VCard from "@/components/VCard";
import RegistrationProgress from "./RegistrationProgress";
import Outline from "./Outline";
import RegistrationDates from "./RegistrationDates";
import Versions from "@/components/templates/Versions";
import TrainingCard from "@/views/learning/trainings/TrainingCard";
import VHint from "@/components/VHint";

export default {
  components: {
    VTitle,
    VHint,
    VCard,
    RegistrationProgress,
    RegistrationDates,
    Versions,
    TrainingCard,
    Outline
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const store = useStore();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { route } = useTraining();

    // Computed
    const isInstructor = computed(() => store.getters["user/isInstructor"]);
    const documentTitle = computed(() => {
      const title = `${t("app.registrations", 2)} - ${t("app.learning")}`;

      const name = getText(data.value?.training?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "learning.registrations.read",
        pathParams: {
          id: props.id
        }
      });

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      isLoading,
      data,
      isInstructor,
      // useTraining
      route
    };
  }
};
</script>
