<template>
  <div
    class="blog rounded-lg col-span-12 md:col-span-6 box"
    :class="{ 'intro-y': hasAnimation }"
  >
    <div
      class="blog__preview rounded-lg image-fit"
      :class="titleClass"
      :style="{ 'background-color': color }"
      @click="$emit('click')"
    >
      <slot name="image" />

      <div class="absolute top-0 right-0 px-5 py-5 z-10">
        <slot name="status" />
      </div>

      <div class="absolute bottom-0 text-white px-5 pb-6 z-10">
        <slot name="author" />

        <div class="font-medium text-xl mt-3">
          <slot name="title" />
        </div>
      </div>
    </div>

    <slot name="content" />
  </div>
</template>

<script>
// Composables
import useAnimation from "@/composables/useAnimation";

export default {
  props: {
    color: {
      type: String,
      default: "#444444"
    },
    titleClass: {
      type: String,
      default: ""
    }
  },
  emits: ["click"],
  setup() {
    // Composables
    const { hasAnimation } = useAnimation();

    return {
      hasAnimation
    };
  }
};
</script>
